<template>

  <div>
    <b-button
      id="toggle-btn"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      class="mb-1"
      variant="primary"
      @click="$router.push({ name: 'clientes' })"
    >
      <feather-icon
        icon="ArrowLeftIcon"
        class="mr-50"
      />
      <span class="align-middle">Voltar</span>
    </b-button>

    <b-row>
      <b-col md="9">
        <b-card>
          <b-media class="mb-2">
            <template #aside>
              <b-avatar
                :src="avatar"
                :text="getInitials(cliente.nome)"
                size="104px"
                rounded
              />
            </template>
            <b-form-group
              label="NOME"
            >
              <div v-if="state.name === false">
                <label
                  class="h5"
                >
                  {{ cliente.nome }}
                </label>
                <feather-icon
                  icon="Edit3Icon"
                  size="16"
                  class="ml-1 color-icon"
                  @click="state.name=!state.name"
                />
              </div>
              <div>
                <b-form-input
                  v-if="state.name == true"
                  id="nome"
                  v-model="cliente.nome"
                  style="color: #fff;"
                  @keydown.enter.prevent="state.name=false"
                />
              </div>
            </b-form-group>
          </b-media>
          <hr>
          <b-form>
            <b-row>
              <b-col
                cols="4"
                md="4"
              >
                <b-form-group
                  label="CPF/CNPJ"
                >
                  <div v-if="state.cpf_cnpj === false">
                    <label class="h5">
                      {{ cliente.cpf_cnpj }}
                    </label>
                    <feather-icon
                      icon="Edit3Icon"
                      size="16"
                      class="ml-1 color-icon"
                      @click="state.cpf_cnpj = true"
                    />
                  </div>
                  <div v-if="state.cpf_cnpj === true">
                    <b-form-input
                      v-model="cliente.cpf_cnpj"
                      style="color: #fff;"
                      @keydown.enter.prevent="state.cpf_cnpj=false"
                    />
                  </div>
                </b-form-group>
              </b-col>

              <b-col
                cols="4"
                md="4"
              >
                <b-form-group
                  label="Endereço"
                >
                  <div v-if="state.endereco === false">
                    <label class="h5">
                      {{ cliente.endereco }}
                    </label>
                    <feather-icon
                      icon="Edit3Icon"
                      size="16"
                      class="ml-1 color-icon"
                      @click="state.endereco = true"
                    />
                  </div>
                  <div v-if="state.endereco === true">
                    <b-form-input
                      v-model="cliente.endereco"
                      style="color: #fff;"
                      @keydown.enter.prevent="state.endereco=false"
                    />
                  </div>
                </b-form-group>
              </b-col>

              <b-col
                cols="4"
                md="4"
              >
                <b-form-group
                  label="CEP"
                >
                  <div v-if="state.cep === false">
                    <label class="h5">
                      {{ cliente.cep }}
                    </label>
                    <feather-icon
                      icon="Edit3Icon"
                      size="16"
                      class="ml-1 color-icon"
                      @click="state.cep = true"
                    />
                  </div>
                  <div v-if="state.cep === true">
                    <b-form-input
                      v-model="cliente.cep"
                      style="color: #fff;"
                      @keydown.enter.prevent="state.cep=false"
                    />
                  </div>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                cols="4"
                md="4"
              >
                <b-form-group
                  label="cidade"
                >
                  <div v-if="state.cidade === false">
                    <label class="h5">
                      {{ cliente.cidade }}
                    </label>
                    <feather-icon
                      icon="Edit3Icon"
                      size="16"
                      class="ml-1 color-icon"
                      @click="state.cidade = true"
                    />
                  </div>
                  <div v-if="state.cidade === true">
                    <b-form-input
                      v-model="cliente.cidade"
                      style="color: #fff;"
                      @keydown.enter.prevent="state.cidade=false"
                    />
                  </div>
                </b-form-group>
              </b-col>

              <b-col
                cols="3"
                md="3"
              >
                <b-form-group
                  label="UF"
                >
                  <div v-if="state.uf === false">
                    <label class="h5">
                      {{ cliente.uf }}
                    </label>
                    <feather-icon
                      icon="Edit3Icon"
                      size="16"
                      class="ml-1 color-icon"
                      @click="state.uf = true"
                    />
                  </div>
                  <div v-if="state.uf === true">
                    <b-form-input
                      v-model="cliente.uf"
                      style="color: #fff;"
                      @keydown.enter.prevent="state.uf=false"
                    />
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
      <b-col md="3">

        <b-row>
          <b-col md="12">
            <b-card>
              <div class="row justify-content-between">
                <h4 class="ml-1">
                  Telefones
                </h4>
                <b-button
                  id="toggle-btn"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  class="mr-1"
                  variant="primary"
                  size="sm"
                  @click="add_telefone = true"
                >
                  <!-- <feather-icon
                    icon="PlusIcon"
                    size="14"
                  /> -->
                  {{ add_telefone }}
                </b-button>
              </div>
              <hr>
              <b-row>
                <b-col md="12">
                  <table
                    class="-2 mt-xl-0 w-90"
                  >
                    <tr
                      v-for="item in telefones"
                      :key="item"
                    >
                      <th class="pb-50">
                        <feather-icon
                          icon="PhoneIcon"
                          class="mr-75"
                        />
                      </th>
                      <td
                        class="pb-50 pl-50 row"
                      >
                        {{ item.telefone }}
                        <feather-icon
                          size="14"
                          icon="Edit3Icon"
                          class="ml-1 color-icon"
                        />
                        <feather-icon
                          size="14"
                          icon="TrashIcon"
                          class="ml-1 trash-icon"
                        />
                      </td>
                    </tr>
                  </table>
                  <div v-if="add_telefone">
                    <!-- <b-form-input
                      v-model="new_telefone"
                      style="color: #fff;"
                      @keydown.enter.prevent="add_telefone=false && new_phone()"
                    /> -->
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="12">
            <b-card>
              <h4>Emails</h4>
              <hr>
            </b-card>
          </b-col>
        </b-row>

      </b-col>
    </b-row>
  </div>

</template>

<script>
import {
  BCard, BButton, BCol, BRow, BAvatar, BMedia, BFormGroup, BForm, BFormInput,
} from 'bootstrap-vue'
import axios from '../../../../axios-auth'

export default {
  components: {
    BCard, BButton, BCol, BRow, BAvatar, BMedia, BFormGroup, BForm, BFormInput,
  },
  data() {
    return {
      //
      state: {
        name: false,
        cpf_cnpj: false,
        endereco: false,
        cep: false,
        cidade: false,
        uf: false,
      },
      //
      add_telefone: false,
      //
      cliente: null,
      telefones: [
        {
          telefone: '(99)99117-1112',
        },
        {
          telefone: '(99)99117-1112',
        },
      ],
      //
      emails: [
        {
          email: 'rafhaelgigalucas@gmail.com',
        },
        {
          email: 'rafhaelgigalucas@gmail.com',
        },
        {
          email: 'rafhaelgigalucas@gmail.com',
        },
        {
          email: 'rafhaelgigalucas@gmail.com',
        },
      ],
      avatar: '',
    }
  },
  async created() {
    await axios.get(`api/v1/clientes/edit/${this.$route.params.id}`, {
      headers: {
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        accept: 'application/json',
      },
    })
      .then(res => {
        this.cliente = res.data.dados.cliente
      })

    //
  },
  mounted() {
    //
  },
  methods: {
    deletCliente() {
      this.$swal({
        title: 'Tem certeza?',
        text: `Deseja deletar o cliente ${this.cliente.nome}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim quero deletar!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios.delete(`api/v1/clientes/delete/${this.cliente.id}`, {
            headers: {
              Authorization: 'Bearer '.concat(localStorage.getItem('token')),
              accept: 'application/json',
            },
          })
            .then(res => {
              if (res.data.sucesso) {
                this.$router.push({ name: 'clientes' })
                this.$swal({
                  icon: 'success',
                  title: 'Deletado!',
                  text: 'Cliente deletado com sucesso!',
                  timer: 1500,
                  showConfirmButton: false,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
        }
      })
    },
    getInitials(name) {
      let initials = name.split(' ')
      if (initials.length > 1) {
        initials = initials.shift().charAt(0) + initials.pop().charAt(0)
      } else {
        initials = name.substring(0, 2)
      }
      return initials.toUpperCase()
    },
    new_phone() {
      this.telefones.push({ telefone: this.new_telefone })
    },
  },
}
</script>
